#legend {
  font-family: Arial, sans-serif;
  background: #fff;
  padding: 10px;
  margin: 10px;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
  border-radius: 2px;
  width: 40px;
}

.legend-icon {
  margin: 2px 0;
}
